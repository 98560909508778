<template>
  <div id="search">
    <b-container>
      <div class="form-group">
        <b-row>
          <b-col></b-col>
          <b-col xs="12">
            <div
              id="destination"
              class="text-nowrap"
              style="text-align: center"
            >
              <input
                class="is-invalid"
                v-model="form.destination"
                type="text"
                placeholder="東京"
                autocomplete="on"
                list="station"
              />
              <!-- Show autocomplete if input exists. This is to avoid keybord being blocked by autocomplete on smartphone -->
              <datalist v-if="form.destination != null" id="station">
                <option v-for="n in station_for_suggestion" :key="n">
                  {{ n }}
                </option>
              </datalist>
              駅<span class="sup">1)</span>から
              <div v-if="!validate.destination" class="invalid-feedback">
                {{ error_message.destination }}
              </div>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col xs="12">
            <div
              id="travel_time"
              class="text-nowrap"
              style="text-align: center"
            >
              通勤時間が
              <input
                style="width: 3em"
                class="is-invalid"
                v-model="form.travel_time"
                placeholder="30"
              />
              分以内の街を
              <div v-if="!validate.travel_time" class="invalid-feedback">
                {{ error_message.travel_time }}
              </div>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col xs="2">
            <div id="submit-button" style="text-align: center">
              <b-button
                variant="success"
                v-on:click.prevent="get_stations_within_travel_time"
                >探す</b-button
              >
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col cols="12">
            <available_line></available_line>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col cols="12">
            <disclaimer></disclaimer>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col cols="12">
            <notice></notice>
          </b-col>
          <b-col></b-col>
        </b-row>

        <b-row>
          <b-col></b-col>
          <b-col>
            <Adsense
              data-ad-client="ca-pub-1040333504284501"
              data-ad-slot="1722443268"
            >
            </Adsense>

            <AutoAdsense> </AutoAdsense>
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>
    </b-container>

    <google_map
      :destination_station="destination_station"
      :destination_position="destination_position"
      :array_of_origin="array_of_origin"
    >
    </google_map>

    <!-- <list_of_results
      :destination_station="destination_station"
      :destination_position="destination_position"
      :array_of_origin="array_of_origin"
    ></list_of_results> -->
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import google_map from "./GoogleMap";
// import list_of_results from "./ListOfResults";

import disclaimer from "./Disclaimer.vue";
import notice from "./Notice.vue";
import available_line from "./AvailableLine.vue";
// 毎回目的地一覧をDBから持ってくると余計に料金がかかるのでファイルとして持っておく
import { station_for_suggestion } from "../station-for-suggestion.js";

export default {
  name: "search",
  components: {
    google_map,
    disclaimer,
    notice,
    available_line,
    // list_of_results,
  },
  data: function () {
    return {
      form: {
        destination: null,
        travel_time: null,
      },
      station_for_suggestion: station_for_suggestion,
      array_of_origin: [],
      destination_station: "東京",
      destination_position: {
        lat: 35.6812362,
        lng: 139.7671248,
      },
      // default error msg of the form
      error_message: {
        destination: "対応していない駅名です",
        travel_time: "指定できる通勤時間は1~120分です",
      },
    };
  },
  computed: {
    validate() {
      const form = this.form;
      // validate travel_time
      let is_valid_number = true; //初期値nullの場合はエラーを表示しない
      if (form.travel_time != null) {
        // 1~120の数字かを判定
        const is_3_digit_number = form.travel_time.match(
          /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$/
        ); // 1-999分までを許容
        const parsed_travel_time = parseInt(form.travel_time, "10");
        const is_less_than_120 =
          parsed_travel_time <= 120 && parsed_travel_time > 0;
        is_valid_number = is_3_digit_number && is_less_than_120 ? true : false;
      }
      const is_valid_travel_time = is_valid_number ? true : false;

      // validate destination
      let is_valid_destination = false;
      const is_initial_input_destination = this.form.destination === null;
      const is_in_valid_destination = this.station_for_suggestion.includes(
        form.destination
      );
      if (is_in_valid_destination || is_initial_input_destination) {
        is_valid_destination = true;
      }
      const result = {
        destination: is_valid_destination,
        travel_time: is_valid_travel_time,
      };
      return result;
    },
  },
  methods: {
    get_stations_within_travel_time() {
      // Delete latest result
      this.array_of_origin = [];

      // input validation
      const is_valid_input =
        this.validate.travel_time &&
        this.validate.destination &&
        this.form.destination != null &&
        this.form.travel_time != null;
      if (!is_valid_input) {
        return;
      }

      // analyticsに検索ログを記録
      const analytics = firebase.analytics();
      analytics.logEvent('search_parameter', {
        destination: this.form.destination,
        travel_time: this.form.travel_time,
      });

      // Read from db
      const self = this;
      const db = firebase.firestore();
      const station_destination = db
        .collection("destination-2024-02-25") //コレクションをここで指定
        .doc(self.form.destination);
      this.destination_station = self.form.destination;

      station_destination
        .get()
        .then(function (doc) {
          if (doc.exists) {
            const data = doc.data();
            self.$set(self.destination_position, "lat", data["lat"]);
            self.$set(self.destination_position, "lng", data["lon"]);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      station_destination
        .collection("origin")
        .where("travel_time_in_min", "<=", Number(self.form.travel_time))
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            const data_dict = doc.data();
            data_dict["destination"] = doc.id;
            self.array_of_origin.push(data_dict);
          });
        });
    },
  },
};
</script>

<style>
.validation-warn {
  color: red;
}
.sup {
  vertical-align: super;
  font-size: 11px;
}
/* .bv-example-row{
  border: 2px solid black;
} */
#destination {
  padding: 5px;
  /* border: 2px solid black; */
}
#travel_time {
  padding: 5px;
  /* border: 2px solid black; */
}
#submit-button {
  padding: 5px;
}
#ad {
  border: 2px solid red;
}
</style>
