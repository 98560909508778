<template>
  <div class="notice">

    お知らせ：
    <ul>
      <li>作者多忙につきアップデートが出来ていませんでしたが、頂いている要望への対応や機能追加を順次実施予定です。</li>
      <li>収益化に向けて協力いただける会社を探しております。ご協力・ご相談いただける場合は機能の要望やバグ報告のフォームまで連絡先を添えてご連絡ください。</li>
    </ul>
    更新履歴：
    <ul>
      <li>2024/11/03 検索が出来なくなっている不具合を解消しました</li>
      <li>2024/09/29 虎ノ門ヒルズ等2024年の駅データに対応しました</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'notice',
}
</script>

<style>
.notice{
  /* border: 2px solid black; */
  font-size: 11px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: left;
}
</style>
