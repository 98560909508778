<template>
  <div id="result">
    <!-- TODO:表示を細かく弄れる様に公式apiでの実装に変更する -->
    <!-- 地図の展開、中心は目的駅 -->
    <GmapMap
      :center="destination_position"
      :zoom="12"
      map-type-id="roadmap"
      style="width: 100%; height: 600px"
    >

    <!-- 勤務地のプロット -->
    <!-- https://www.flaticon.com/free-icon/location-pin_106121 -->
    <!-- https://stackoverflow.com/questions/51800404/custom-marker-for-vue2-google-maps -->
    <GmapMarker
      :position="destination_position"
      :clickable="true"
      :draggable="false"
      :label="label_station"
      :icon="{url: require('../assets/location-pin.png')}"
      @click="center=station.latlng"
    />

    <!-- 通勤圏のプロット -->
    <GmapMarker
    :key="index"
    v-for="(dic, index) in array_of_origin"
    :position="{lat: dic.lat, lng: dic.lon}"
    :clickable="true"
    :draggable="false"
    @click="toggleInfoWindow(dic)"
    />
    <GmapInfoWindow
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen=false"
    >
    </GmapInfoWindow>
  </GmapMap>

  <!-- <ul id="example-1">
      <li v-for="(item, index) in array_of_origin" :key="index">
        {{ item}}
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: 'result',
  props: ['destination_station', 'destination_position', 'array_of_origin'],
  data: function () {
    return {
      label_station: {text:"職場",color:"#FFF", fontSize:"15",},
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        },
        content: ""
      },
      infoWindowPos: null,
      infoWinOpen: false
    }
  },
  created: function () {
  },
  methods: {
    onDragEnd() {
      console.log("hoge");
    },
    // 駅の詳細情報を表示するメソッド
    // 小さいものなのでここのcssはstyleに直接書き込んでしまう
    // TODO: 別ファイルに切り出す
    toggleInfoWindow(marker) {
      this.infoWindowPos = {lat: marker.lat, lng: marker.lon};
      this.infoWinOpen = true;
      this.infoOptions.content = `
      <div class="station_name">${marker.destination}駅</div>
      <div class="travel_time" style="margin-left: 1em;">通勤時間：${marker.travel_time_in_min}分</div>
      <div class="chuukai_info" style="margin-left: 1em;">
        周辺の物件を仲介サイトで探す(※α版)<br>
        <ul>
          <li>
          賃貸物件：
          <a href="https://www.chintai.net/select/?searchEkiName=${marker.destination}&prefKey=tokyo" target="_blank">CHINTAI</a>
          、
          <a href="https://suumo.jp/jj/chintai/ichiran/FR301FC011/?ar=030&bs=040&fw=${marker.destination}" target="_blank">SUUMO</a>
          </li>
          <li>新築マンション：<a href="https://suumo.jp/jj/bukken/ichiran/JJ010FJ001/?ar=030&bs=010&fw=${marker.destination}" target="_blank">SUUMO</a></li>
          <li>中古マンション：<a href="https://suumo.jp/jj/chintai/ichiran/FR301FC011/?ar=030&bs=040&fw=${marker.destination}" target="_blank">SUUMO</a></li>
        </ul>
      </div>
      `
    },
  }
}
</script>

<style>
.station_name{
  font-weight:  bold;
}
</style>
